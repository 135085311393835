import React from 'react'

function TeamMem() {
  return (
	 <section className="team-member-area pt-75 pb-75" id="equipo">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three text-center mb-70">
                <h2>Nuestro <span>equipo</span></h2>
                <p>Somos una empresa familiar con más de 10 años de experiencia, 100% generaleños, comprometidos con usted para solucionar sus problemas tecnológicos.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/team_member1.jpg" alt="" />
                </div>
                <div className="team-member-content">
                  <h4>Josue Venegas</h4>
                  <span>Técnico</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/team_member2.jpg" alt="" />
                </div>
                <div className="team-member-content">
                  <h4>Harold Valverde</h4>
                  <span>Asistente Técnico</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/team_member3.jpg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4>Alvaro Venegas</h4>
                  <span>Ventas</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/team_member4.jpg" alt="" />
                </div>
                <div className="team-member-content">
                  <h4>Pablo Venegas</h4>
                  <span>Ingeniero en Sistemas</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default TeamMem