import React, { useState } from 'react'
import TeamMem from '../../../pages/aboutpage/aboutuspage/TeamMem'
import ContactArea from '../../../pages/contact/ContactArea'
import Footer from '../../footer/Footer'
import HeaderFour from '../../header/HeaderFour'
import BlogArea from './BlogArea'
import BrandArea from './BrandArea'
import SliderArea from './SliderArea'

const IndexFour = () => {

  const [ orderNumber, setOrderNumber] = useState('')
  const [isHover, setIsHover] = useState(false);

   const handleMouseEnter = () => {
      setIsHover(true);
   };

   const handleMouseLeave = () => {
      setIsHover(false);
   };

   const buttonStyle = () => {
    if(isHover){
      return {
        width: '100%', 
        color:'#fff',
        backgroundColor:'#279496'
      }
    }
    return {
      width: '100%', 
      color:'#fff'
    }
   }


  const onChangeOrderNumber = (event) =>{
    let reg = /^(\s*|\d+)$/;
    if(reg.test(event.target.value)){
      setOrderNumber(event.target.value)
    }
  }

  return (
    <>  
    <HeaderFour/>
   
    <main>
      <SliderArea/>
      
      <section className='pt-75 pb-75'>
        <div className="section-title title-style-three text-center">
            <h2>CONSULTA EL ESTADO DE TU REPARACIÓN</h2>
        </div>
        <div className='row pl-10 pr-10 justify-content-md-center d-flex align-items-center'>
          <div className='col-md-4 col-sm-12'>
            <div className="mb-3">
              <label htmlFor="search_my_order" className="form-label">Escribe el número de tu orden</label>
              <input maxLength="10" className="form-control" id='search_my_order' type="text" placeholder="Por ejemlo: 874575" aria-label="search_my_order" value={orderNumber} onChange={onChangeOrderNumber}></input>
            </div>
          </div>
          <div className='col-md-2 col-sm-12'>
            <a href={`https://nissi.repair/myorder/TITANCELL/${orderNumber}`} target='_blank'>
              <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} type="button" style={buttonStyle()} className="btn btn_buscar" id='btn_buscar'>Buscar</button>
            </a>
          </div>
        </div>
      </section>
      <section className='pt-75 pb-75'>
        <a href='https://wa.me/c/50670807846' target='_blank'>
          <div className="section-title title-style-three text-center">
            <h2>MIRA NUESTRO <span>CATÁLOGO</span> </h2>
            <img src="assets/img/slider/clic.png" alt="" />
          </div>
        </a>
      </section>
      <BrandArea/>
      <BlogArea />
      <TeamMem />
      <section className="team-member-area pt-75 pb-75" id="contacto">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three text-center mb-70">
                <h2>Déjenos ser <span>su primer opcion</span></h2>
                {/* <p>Somos una empresa familiar con más de 10 años de experiencia, 100% generaleños, comprometidos con usted para solucionar sus problemas tecnológicos.</p> */}
              </div>
            </div>
          </div>
          <ContactArea/>
        </div>
      </section>
      
    </main>
    <Footer/>
    </>
  )
}

export default IndexFour