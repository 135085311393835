import React,{ useEffect } from 'react'
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";

const HeaderFour = () => {

  useEffect(()=>{
    //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>');

      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .push-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });


        $('.menu-backdrop, .mobile-menu .close-btn').click (()=>{
          $('body').removeClass('mobile-menu-visible');
        })


        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });
      }

  },[]);
  useEffect(()=>{
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');
    
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });
    
  },[])
  
  return (
    <header className="header-style-four">
    <div id="sticky-header" className="header-four-wrap main-header menu-area">
      <div className="container custom-container-two">
        <div className="row">
          <div className="col-12">
            <div className="mobile-nav-toggler">
              <i className="fas fa-bars" />
            </div>
            <div className="main-menu menu-style-two">
              <nav>
                <div className="logo">
                  <a href="/"><img src="assets/img/logo/logo_titancell.png" alt="Logo" /></a>
                </div>
                <div id="mobile-menu" className="navbar-wrap push-menu d-none d-lg-flex">
                  <ul className="navigation">
                    <li><a  href="/">INICIO</a></li>
                    <li><a  href="#servicios">NUESTROS SERVICIOS</a></li>
                    <li><a href="#equipo">ACERCA DE NOSOTROS</a></li>
                    <li><a href="#contacto">CONTACTO</a></li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times" />
                    </div>
                    <div className="nav-logo">
                      <Link to="/"><img src="assets/img/logo/logo_titancell.png" alt="" title='true' /></Link>
                    </div>
                    <div className="menu-outer">
                  
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
          </div>
        </div>
      </div>
    </div>
  </header>
  )
}

export default HeaderFour