import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import ReactGA from "react-ga4";

ReactGA.initialize("G-5T0W1HZGGH");

ReactGA.send({
    hitType: "pageviw",
    page: window.location.pathname
});

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

