import React from 'react'

const BlogArea = () => {
  return (
    <section className="blog-area pt-115 pb-75" id="servicios">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="section-title title-style-three text-center mb-65">
              <h2>Nuestros <span>Servicios</span></h2>
              <p>Entre nuestros servicios puedes encontrar diseño de páginas web, programación, accesorios, mouse, teclados, reparación de pantallas, sistemas de carga, desbloqueos, instalación de Windows, paquetes de Office, activaciones de Windows, entre otros.</p>
            </div>
          </a>
          
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-sm-9">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <img src="assets/img/blog/blog_lcd.jpg" alt="" />
              </div>
              <div className="blog-post-content">
                <h4>Cambio de LCD</h4>
                <p>Reemplazo de pantallas y touch, variedad en calidades, con o sin marco.</p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-9">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <img src="assets/img/blog/blog_sistemasdecarga.jpg" alt="" />
              </div>
              <div className="blog-post-content">
                <h4>Reparación de sistema de carga</h4>
                <p>Cambio de puerto de carga, reconstrucción de pistas, adaptación de rack, reemplazo de paleta completa de carga.</p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-9">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <img src="assets/img/blog/blog_mantenimiento.jpg" alt="" />
              </div>
              <div className="blog-post-content">
                <h4>Mantenimiento de computadora</h4>
                <p>Limpieza general, lavado de placa, cambio de pasta térmica, uso de thermal pad.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-sm-9">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <img src="assets/img/blog/blog_software.jpg" alt="" />
              </div>
              <div className="blog-post-content">
                <h4>Software celulares</h4>
                <p>Actualización de aplicaciones, actualización de sistema, desbloqueo, reinstalación de firmware.</p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-9">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <img src="assets/img/blog/blog_instalacion.jpg" alt="" />
              </div>
              <div className="blog-post-content">
                <h4>Programas Computadoras</h4>
                <p>Instalación de sistemas operativos, activación de Windows y programas, paquetes de Office, programas de diseño y más.</p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-9">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <img src="assets/img/blog/blog_microelectronica.jpg" alt="" />
              </div>
              <div className="blog-post-content">
                <h4>Lab microelectrónica</h4>
                <p>Solución a problemas de equipos apagados, corrección de cortos, cambios de ic, diagnóstico y más.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-sm-9">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <img src="assets/img/blog/blog_accesorios.jpg" alt="" />
              </div>
              <div className="blog-post-content">
                <h4>Accesorios</h4>
                <p>Variedad en accesorios para computadoras y celulares, cargadores, cables, parlantes, protectores, audífonos y muchos artículos más</p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-9">
          <a href='https://wa.me/c/50670807846' target='_blank'>
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <img src="assets/img/blog/blog_desarrollo.jpg" alt="" />
              </div>
              <div className="blog-post-content">
                <h4>Desarrollo/Programación</h4>
                <p>Desarrollo de páginas web, aplicaciones móviles y de escritorio, sistemas hechos a la medida.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
  )
}

export default BlogArea