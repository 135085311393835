import React from 'react';
import { useState, } from 'react';
import ReactMapGl, { Layer, Map, FlyToInterpolator, NavigationControl, Source } from 'react-map-gl';

import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = 'pk.eyJ1IjoicGFibG92ZTAwIiwiYSI6ImNsZGphNTV6ZjFicHQzdm51Y2Exbno1MWoifQ.0pYP8NIcKYWAUIafCit83Q';

function ContactArea() {


  const geojson = {
    type: 'FeatureCollection',
    features: [
      {type: 'Feature', geometry: {type: 'Point', coordinates: [-83.70398560037424, 9.375677065557092]}}
    ]
  };
  
  const layerStyle = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': '#007cbf'
    }
  };

  const [viewport, setViewport] = useState({
    latitude: 9.375677065557092,
    longitude: -83.70398560037424,
    zoom: 16,
  });

  

  return (
	<section className="contact-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div id="contact-map">
                <Map
                  attributionControl={false}
                  initialViewState={{
                    longitude: -83.70398560037424,
                    latitude: 9.375677065557092,
                    zoom: 17,
                    
                  }}
                  mapboxApiAccessToken="pk.eyJ1IjoicGFibG92ZTAwIiwiYSI6ImNsZGphODd3aTFwbzIzcW1tNmxnN3VnazYifQ.n3VR_yN9YwLvCgGuv5wcuQ"
                  mapStyle="mapbox://styles/mapbox/streets-v9"
                >
                  <Source id="my-data" type="geojson" data={geojson}>
                    <Layer {...layerStyle} />
                  </Source>
                </Map>
              </div>
            </div>
            <div className="col-lg-6 pl-45">
              <div className="section-title title-style-three mb-20">
                <h2>CONTÁCTENOS</h2>
              </div>
              <div className="contact-info-list mb-40">
                <ul>
                  <li><i className="fas fa-map-marker-alt" />25 metros oeste del Complejo Cultural, frente al centro comercial Arelys</li>
                  <li><i className="fas fa-envelope" /><a className='a_contact' href="mailto:titancell.cr@gmail.com" target="_blank">titancell.cr@gmail.com</a></li>
                  <li><i className="fab fa-facebook"></i><a className='a_contact' href='https://www.facebook.com/titanpz' target="_blank">/titanpz</a></li>
                  <li><i className="fas fa-phone"></i><a className='a_contact' href='tel:+50647019211' target="_blank">4701 9211</a></li>
                  <li><i className="fas fa-mobile"></i><a className='a_contact' href='tel:+50670807846' target="_blank">7080 7846</a></li>
                </ul>
              </div>
              {/* <div className="contact-form">
                <form>
                  <textarea name="message" id="message" placeholder="Escribe un mensaje..." defaultValue={""} />
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text" placeholder="Tu nombre" />
                    </div>
                    <div className="col-md-6">
                      <input type="email" placeholder="Tu email" />
                    </div>
                  </div>
                  <button>ENVIAR MENSAJE</button>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>
  )
}

export default ContactArea